import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/sapling/sapling/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/node_modules/.pnpm/next@15.2.4_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"block\",\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/sapling/sapling/node_modules/.pnpm/sonner@2.0.2_react-dom@19.1.0_react@19.1.0__react@19.1.0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/sapling/sapling/packages/www/src/components/client/google-tag-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/home/runner/work/sapling/sapling/packages/www/src/components/client/trpc/trpc-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/styles/globals.css");
